import { Route } from '@angular/router';
import { AccessDeniedViewComponent } from '@troyai/troy-leads/auth-shell';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('@troyai/leads/leads-shell').then((m) => m.LEADS_ROUTES),
  },
  {
    path: 'forbidden',
    component: AccessDeniedViewComponent,
    title: 'Forbidden',
  },
  {
    path: '**',
    pathMatch: 'full',
    component: AccessDeniedViewComponent,
  },
];
