import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginFlowInitiated, UserState } from '@troyai/troy-leads/auth/tl-auth-data-access';
import { IconsModule } from '@troyai/ui-kit';

@Component({
  selector: 't-main-menu',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './main-menu.component.html',
})
export class MainMenuComponent {
  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {}

  userDetails$ = this.store.select(UserState.userDetails);
  userLoading$ = this.store.select(UserState.isLoading);

  destroyRef = inject(DestroyRef);

  loginWithRedirect(): void {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.store.dispatch(
        new LoginFlowInitiated({
          redirectUrl: params['returnUrl'],
        })
      );
    });
  }
}
