import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, switchMap, take } from 'rxjs';

import { UserState } from '@troyai/troy-leads/auth/tl-auth-data-access';

export const UserKeyInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const userKey$ = store.select(UserState.userKey);

  return userKey$.pipe(
    distinctUntilChanged(),
    take(1),
    switchMap((userKey) => {
      if (userKey) {
        const authReq = req.clone({
          headers: req.headers.set('X-User-ID', userKey),
        });

        return next(authReq);
      } else {
        return next(req);
      }
    })
  );
};
