import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule, Store } from '@ngxs/store';

import { AuthHttpInterceptor, AuthModule, HttpMethod } from '@auth0/auth0-angular';
import { LeadsState } from '@troyai/leads/leads-data-access';
import { API_BASE_URL, RestApiClientService } from '@troyai/rest-api-client';
import { initializeApp, UserState } from '@troyai/troy-leads/auth/tl-auth-data-access';
import { UserKeyInterceptor } from '@troyai/troy-leads/auth/tl-auth-feature';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

let devImports = [NgxsReduxDevtoolsPluginModule.forRoot()];

if (environment.production) {
  devImports = [];
}

export const getAuth0RedirectUri = () => {
  const currentUrl = new URL(window.location.href);
  const url = `${currentUrl.origin}/auth-callback`;
  return url;
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([UserKeyInterceptor])),
    provideAnimations(),
    importProvidersFrom(
      NgxsModule.forRoot([LeadsState, UserState], {
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
        developmentMode: !environment.production,
      }),
      AuthModule.forRoot({
        domain: environment.auth0ConnectionData.domain,
        clientId: environment.auth0ConnectionData.clientId,
        authorizationParams: {
          audience: environment.auth0ConnectionData.audience,
          scope: 'profile email',
          redirect_uri: getAuth0RedirectUri(),
        },
        httpInterceptor: {
          allowedList: [
            {
              uri: `${environment.apiUrl}/*`,
              httpMethod: HttpMethod.Get,
              allowAnonymous: true,
            },

            {
              uri: `${environment.apiUrl}/*`,
              httpMethod: HttpMethod.Post,
              allowAnonymous: true,
            },
            {
              uri: `${environment.apiUrl}/*`,
              httpMethod: HttpMethod.Patch,
              allowAnonymous: true,
            },
            {
              uri: `${environment.apiUrl}/*`,
              httpMethod: HttpMethod.Delete,
              allowAnonymous: true,
            },
            {
              uri: `${environment.apiUrl}/*`,
              httpMethod: HttpMethod.Put,
            },
          ],
        },
      }),
      ...devImports
    ),
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: RestApiClientService,
      useClass: RestApiClientService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Store],
    },
    {
      provide: API_BASE_URL,
      useValue: environment.apiUrl,
    },
  ],
};
