import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Actions, ofActionErrored, Store } from '@ngxs/store';
import { MinimalLayoutComponent } from '@troyai/troy-leads-layout';
import { GetCurrentUserDetails, UserState } from '@troyai/troy-leads/auth/tl-auth-data-access';
import { MainMenuComponent } from '@troyai/troy-leads/tl-nav';
import { LoaderComponent } from '@troyai/ui-kit';

@Component({
  standalone: true,
  imports: [RouterModule, MinimalLayoutComponent, CommonModule, LoaderComponent, MainMenuComponent],
  selector: 't-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions
  ) {
    this.actions$.pipe(ofActionErrored(GetCurrentUserDetails)).subscribe(() => {
      this.router.navigateByUrl('/forbidden');
    });
  }

  userLoading$ = this.store.select(UserState.isLoading);
}
