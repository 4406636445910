<div class="flex gap-x-3">
  @if ((userLoading$ | async) === false) {
    @if (userDetails$ | async; as userDetails) {
      <div
        class="text-grey-90 shadow-small hover:bg-grey-10 flex items-center gap-x-2 rounded-lg bg-white px-8 py-3 text-xs font-semibold hover:cursor-pointer">
        <span>Hi, {{ userDetails.first_name }}</span>
        <t-icon key="face" color="inherit" size="small" />
      </div>

      <!-- <div
        class="hover:bg-grey-10 text-grey-90 shadow-small flex items-center rounded-lg bg-white px-8 py-3 text-xs font-semibold hover:cursor-pointer">
        <span>Subscribe</span>
      </div> -->
    } @else {
      <div
        (click)="loginWithRedirect()"
        class="text-grey-90 shadow-small hover:bg-grey-10 flex items-center gap-x-2 rounded-lg bg-white px-8 py-3 text-xs font-semibold hover:cursor-pointer">
        <span>Sign in</span>
        <t-icon key="face" color="inherit" size="small" />
      </div>
    }
  }
</div>
