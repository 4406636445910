<t-minimal-layout>
  <t-main-menu nav />
  @if (userLoading$ | async) {
    <div class="absolute left-0 top-0 flex h-screen w-screen items-center justify-center">
      <t-loader size="large" />
    </div>
  } @else {
    <router-outlet />
  }
</t-minimal-layout>
