const apiHost = window.location.hostname;
const apiUrl = `https://${apiHost}/api`;

export const environment = {
  production: true,
  enableDebugTools: false,
  logLevel: 'error',
  apiHost,
  apiUrl,
  auth0ConnectionData: {
    domain: 'auth.troyleads.com',
    clientId: 'rEgpBT4iPq0FinLWC5kPAZwk9cj8MFOm',
    audience: 'https://api.troyleads.com',
  },
};
