<div class="custom-bg relative">
  <div class="min-h-screen w-full">
    <div class="absolute left-0 top-0 w-full px-2 pt-8 lg:px-0">
      <div class="mx-auto flex max-w-6xl items-center justify-between">
        <a routerLink="/" class="block h-auto w-[183px] flex-none">
          <img src="assets/troy-leads-logo.svg" alt="logo" class="block h-auto w-[183px]" />
        </a>

        <ng-content select="[nav]" />
      </div>
    </div>

    <div class="mx-auto max-w-6xl pt-[122px]">
      <ng-content></ng-content>
    </div>
  </div>
</div>
